import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import translations from 'assets/translations/translations'
import LinkItem from 'components/Navbar/Links/LinkItem'
import MEDIA from 'utils/helpers/media'
import SmallHeader from 'components/Typography/Headers/SmallHeader'
import NormalText from 'components/Typography/Paragraphs/NormalText'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
`

const LinkContainer = styled.div`
  justify-self: center;
`

const Header = styled.div`
  margin-bottom: 10px;
  ${MEDIA.MIN_TABLET`
  margin-bottom: 20px;
    `}
`
const ReadAll = styled.div`
  font-size: ${(props) => props.theme.fontSizes.mheadSmall};
  align-self: center;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  color: ${(props) => props.theme.colors.blue};
  margin-top: 10px;
  display: inline-block;
  justify-self: center;

  &:hover {
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }
  ${MEDIA.MIN_TABLET`
  font-size: ${(props) => props.theme.fontSizes.theadSmall};
    `}
`

const MarginTop = styled.div`
  margin-top: 10px;
`

const Date = styled.div`
  margin-top: 5px;
  font-size: ${(props) => props.theme.fontSizes.msmall};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin-bottom: 20px;
  ${MEDIA.MIN_TABLET`
  font-size: ${(props) => props.theme.fontSizes.tsmall};
    `}
`

const NewsItem = ({ newsLink, fluidImage, title, description, date }) => (
  <Wrapper>
    <Header>
      <SmallHeader white={false} align="center">
        {title}
      </SmallHeader>
    </Header>
    <Img fluid={fluidImage} />
    <Date>{date}</Date>
    <MarginTop />
    <NormalText weight={300} align="center" breakWord>
      {description}
    </NormalText>
    <LinkContainer>
      <LinkItem noAnimation link={newsLink}>
        <ReadAll>{translations.UI.readAll}</ReadAll>
      </LinkItem>
    </LinkContainer>
  </Wrapper>
)

NewsItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fluidImage: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  newsLink: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
}

export default NewsItem
