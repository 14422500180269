import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import ListItem from 'components/News/NewsItem/NewsItem'
import MEDIA from 'utils/helpers/media'

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.blue};

  &:last-child {
    display: none;
  }
`

const PageContainer = styled.div`
  max-width: 300px;
  margin: 0 auto;
  ${MEDIA.MIN_TABLET`
  max-width: 700px;
    `}
  ${MEDIA.MIN_BIGDESKTOP`
  max-width: 800px;
    `}
`

const NewsContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 30px;
  grid-template-columns: 100%;
  ${MEDIA.MIN_TABLET`
  grid-row-gap: 50px;
    `}
`

const PageList = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-column-gap: 10px;
  margin: 20px 0 50px 0;
`
const PageNumber = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.colors.blue};
  font-weight: ${(props) =>
    props.clicked
      ? props.theme.fontWeights.bold
      : props.theme.fontWeights.light};
  transition: all 0.2s ease-out;

  &:hover {
    font-weight: ${(props) => props.theme.fontWeights.medium};
  }
`

const News = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allMarkdownRemark {
        edges {
          node {
            id
            frontmatter {
              title
              date
              slug
              description
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const dataArray = data.allMarkdownRemark.edges.sort((a, b) => {
    const d1 = new Date(a.node.frontmatter.date)
    const d2 = new Date(b.node.frontmatter.date)
    return d2.getTime() - d1.getTime()
  })
  const numberPerPage = 4
  const [currentPage, setCurrentPage] = useState(1)
  const [pages, setPages] = useState(null)
  const [newsDisplayed, setNewsDisplayed] = useState(null)

  const handleClick = (e) => {
    setCurrentPage(Number(e.target.id))
  }

  useEffect(() => {
    const sliceNews = (news) => {
      const indexOfLastNews = currentPage * numberPerPage
      const indexOfFirstNews = indexOfLastNews - numberPerPage
      const currentNews = news.slice(indexOfFirstNews, indexOfLastNews)
      return currentNews
    }
    const showNews = (news) => {
      const slicedNews = sliceNews(news)
      return slicedNews.map((el) => (
        <>
          <ListItem
            key={el.node.frontmatter.slug}
            fluidImage={el.node.frontmatter.featuredImage.childImageSharp.fluid}
            description={el.node.frontmatter.description}
            date={el.node.frontmatter.date}
            title={el.node.frontmatter.title}
            newsLink={el.node.frontmatter.slug}
          />
          <Line />
        </>
      ))
    }

    const news = showNews(dataArray)
    setNewsDisplayed(news)
  }, [dataArray, currentPage])

  useEffect(() => {
    const pageNumbers = []
    for (let i = 1; i <= Math.ceil(dataArray.length / numberPerPage); i += 1) {
      pageNumbers.push(i)
    }

    setPages(
      pageNumbers.map((number) => (
        <PageNumber
          key={number}
          id={number}
          clicked={number === currentPage}
          onClick={handleClick}
        >
          {number}
        </PageNumber>
      )),
    )
  }, [dataArray, currentPage])

  return (
    <PageContainer>
      <NewsContainer>{newsDisplayed}</NewsContainer>
      <PageList>{pages}</PageList>
    </PageContainer>
  )
}

export default News
