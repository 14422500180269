import React from 'react'
import { useSelector } from 'react-redux'
import SEO from 'components/SEO/SEO'
import translations from 'assets/translations/translations'
import News from 'components/News/News'
import PageHeader from 'components/Typography/Headers/PageHeader'
import PageIntro from 'components/PageIntro/PageIntro'
import Footer from 'components/Footer/Footer'
import MarginBottom from 'components/UI/MarginBottom/MarginBottom'

const Wiadomosci = () => {
  const location = useSelector(({ state }) => state.targetRoute)

  return (
    <>
      <SEO title={translations.pages.news} pathname={location.pathname} />
      <PageIntro
        sectionLineHeader={translations.pages.news}
        description={translations.descriptions.news}
      >
        <PageHeader>{translations.headers.news.newest}</PageHeader>
        <PageHeader blue>{translations.headers.news.information}</PageHeader>
        <PageHeader>{translations.headers.news.tax}</PageHeader>
      </PageIntro>
      <News />
      <MarginBottom />
      <Footer />
    </>
  )
}

export default Wiadomosci
