import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MEDIA from 'utils/helpers/media'

const Header = styled.div`
  color: ${(props) =>
    props.white ? props.theme.colors.white : props.theme.colors.black};
  font-size: ${(props) => props.theme.fontSizes.mheadregular};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  ${MEDIA.MIN_TABLET`
    font-size: ${(props) => props.theme.fontSizes.theadregular};
    `}
`
const SmallHeader = ({ align, white, children }) => (
  <Header align={align} white={white}>
    {children}
  </Header>
)

SmallHeader.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  white: PropTypes.bool,
}

SmallHeader.defaultProps = {
  white: true,
  align: 'left',
}
export default SmallHeader
